import { Link } from "gatsby";
import React, { useEffect } from "react";
import { IS_GIFT_CARD_GLOBAL } from "../../config/featureFlag";
import { GTM_LEAD_GIFT_PRICELIST } from "../../config/gtm_classes";
import AccordionPrices from "../AccordionPrices";
import Alert from "../Alert";
import BubbleSection from "../BubbleSection";
import Button from "../Button";

import useInViewPort from "../useInViewPort";

const GiftCardCTA = ({ city = "all" }) => (
  <Button type="primary">
    <Link
      to={`/bon-podarunkowy`}
      title="Kup Bon Podarunkowy"
      className={`${GTM_LEAD_GIFT_PRICELIST}`}
    >
      Podaruj Bon
    </Link>
  </Button>
);

const PriceList = ({ className = "", data, ifGistAvailable = true }) => {
  const [nodeRef, isVisible] = useInViewPort();

  useEffect(() => {
    if (isVisible && typeof window !== "undefined") {
      const mydrScript = document.getElementsByTagName("script")[0];

      const js = document.createElement("script");

      js.src = "https://mydr.pl/static/mydr-pp.min.js";

      mydrScript.parentNode.insertBefore(js, mydrScript);

      js.onload = () => {
        const PatientsPlugin = new window.PatientsPlugin();

        PatientsPlugin.init({
          app: "https://mydr.pl/patients_plugin",
          plugin: "https://mydr.pl/static",
        });
      };
    }
  }, [isVisible]);


  return (
    <div ref={nodeRef} className={className}>
      <a className="anchor" id="cennik"></a>
      <BubbleSection
        h2="Cennik naszych usług"
        h5="Rozwiń kategorię, aby umówić się online"
        color="gray"
      >
        <div className="container">
          <AccordionPrices priceList={data} />
          {/* <Alert withImage={false}>
            * - Dołącz do programu profilaktyki zgodnie z zaleceniami lekarza i
            zapłać mniej za badanie oraz zyskaj zniżki na nasze pozostałe usługi
            - zapytaj o szczegóły w recepcji ośrodka.
          </Alert> */}
          <br />
          {ifGistAvailable && IS_GIFT_CARD_GLOBAL && (
            <Alert button={GiftCardCTA} withImage={false}>
              <strong>Pomyśl o najbliższych</strong> i już dziś skieruj ich na
              badania profilaktyczne w kierunku nowotworów skóry.
            </Alert>
          )}
        </div>
      </BubbleSection>
    </div>
  );
};

export default PriceList;
